import React from 'react';
import PropTypes from 'prop-types';
import styles from './SplitContainer.module.css';

function SplitContainer({ className, children, childStyle }) {
  const flexBasis = `${Math.floor(100 / children.length)}%`;

  return (
    <div className={className}>
      {children.map((child, i) => {
        return (
          <div
            key={i}
            className={styles.splitChild}
            style={{ flexBasis, ...childStyle }}
          >
            {child}
          </div>
        );
      })}
    </div>
  );
}

SplitContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.array,
  childStyle: PropTypes.object,
};

SplitContainer.defaultProps = {
  className: styles.splitContainer,
};

export default SplitContainer;
