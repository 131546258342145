import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import SplitContainer from '../components/SplitContainer';
import CTA from '../components/CTA';
import styles from './about.module.css';
import Divider from '../components/Divider';

function TeamPage() {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "team.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hannahImage: file(relativePath: { eq: "hannah.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout
      renderHero={() => {
        return (
          <Hero
            fluidImage={data.heroImage.childImageSharp.fluid}
            title="About Me"
          />
        );
      }}
    >
      <SEO title="About Me" />

      <SplitContainer childStyle={{ flexBasis: 'auto' }}>
        {[
          <Fragment key="photo">
            <Img
              className={styles.photo}
              fluid={data.hannahImage.childImageSharp.fluid}
            />
          </Fragment>,
          <Fragment key="profile">
            <h2 className={styles.name}>Hannah Matthys, Phd.</h2>
            <div>
              <em>She / Her / Hers</em>
            </div>
            <p>
              This work is incredibly personal to me, rooted in my lived
              experiences and developed through my professional career. As an
              infant, my white parents adopted me from South Korea, leading
              me to grow up in a small, rural town in Wisconsin. I was one of
              a handful of children of color in that predominantly white,
              farming community, and had to navigate my identity alone. I
              worked hard in school and became the first to go to college in
              my family, paying for it through a combination of financial
              aid, loans, and working odd jobs. Eventually, I went on to earn
              my PhD, which provided me with an academic foundation in this
              work.
            </p>
            <p>
              To add complexity to this experience, I have several Black
              siblings who have very different outcomes and live very
              different lives, despite growing up in the same household with
              the same rules and values. Unlike me, none have graduated
              college and one doesn’t have a GED.
            </p>
            <p>
              I was privileged enough to earn my PhD from the University of
              Colorado at Boulder, where I focused on identity development
              and systemic oppression. This helped me to develop a lens and
              discover the words to describe the inequities that permeated my
              existence. Equipped with this knowledge and expertise, my goal
              now is to provide access to theories and conversations that
              often live in the world of academia, grounding them in
              clients&apos; lived experiences and centering them in growth
              and transformation.
            </p>
            <p>
              This expertise and experience has been developing over 10 years
              of teaching and facilitation. I have taught this content at the
              high school, college, Masters, and PhD levels. I have
              facilitated and provided keynotes at conferences such as the
              White Privilege Institute Conference, Teachers of Color and
              Allies Conference, and the Jeffco Diversity Expo. I have also
              led this work in school districts, nonprofits, startups, sports
              organizations, and government agencies.
            </p>
          </Fragment>,
        ]}
      </SplitContainer>

      <Divider />

      <CTA to="/testimonials/">Read My Testimonials</CTA>
    </Layout>
  );
}

export default TeamPage;
